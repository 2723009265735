.hero{
    display: flex;
    justify-content: space-between;
}

.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.right-h{
    flex: 1;
    background-color: rgb(207, 159, 69);
}


.the-best-ad{
    margin-top: 4rem; 
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding:20px 13px;
    text-transform:uppercase ;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad>div{
    z-index: 1;
    position: absolute;
    background-color: orange;
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
}
.stroke-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.hero-text{
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow:inherit

}

.hero-text>div:nth-of-type(3){
    font-size:1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;

}

.figures{
    display: flex;
    gap:2rem;
}

.figures>div{
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
    text-transform:uppercase ;
    color: #9c9c9c;
}

.btn{
    background-color: white;
    padding: 0.5rem;
    font-weight: bold;
    border:4px solid transparent;
    transition: 300ms all;
}

.btn:hover{
    cursor: pointer;
}

.hero-button{
    display: flex;
    gap:1rem;
    font-weight: normal;
}

.hero-button>:nth-of-type(1)
{
    color: white;
    background-color: orange;
    width: 8rem;
}
.hero-button>:nth-of-type(2)
{
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid orange;
}

.right-h>button{
    margin-left: 15rem;
    margin-top: 2rem;
   
}
.heart-rate{
    display: flex;
    flex-direction: column;
    background-color: #565960;
    width: fit-content;
    align-items: start;
    padding: 1rem;
    gap:1rem;
    border-radius: 5px;
    position: absolute;
    top: 7rem;
    margin-left: 13rem;

}
.heart-rate>img{
    width: 2rem;
}
.heart-rate>span:nth-of-type(1){
    color: #9c9c9c;
}

.heart-rate>span:nth-of-type(2){
    color: white;
    font-weight: bold;
    font-size: 1.5rem;

}

.hero-image{
    position: absolute;
    top:13rem;
    right: 8rem;
    width: 25rem;
    height: 30rem;  
    z-index: 2; 
}

.hero-image-back{
    position: absolute;
    top: 4rem;
    right:23rem;
    z-index: 1;
    width: 15rem;
}

.hero-blur{
  width: 6rem;
  height: 13rem;
  
}

@media screen and(max-width:768px) {
    .hero{
        flex-direction: column;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self:center ;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .hero-image-back> div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .figures >div>span:nth-of-type(1){
        font-size: large;
    }
    .figures >div>span:nth-of-type(2){
        font-size: small;
    }
    .right-h{
        position: relative;
        background: none;
    }
    .heart-rate{
        left: 1rem;
        top:2rem;
    }
    .hero-image{
        position:relative;
        width:"15rem";
        left: 7rem;
        top: 4rem;
        align-self: center;
    }
    .hero-image-back{
        width: 15rem;
        left: 2rem;
        top: 0rem;
    }
}