.program{
    margin-top: 4.5rem;
    display: flex;
    flex-direction: column;
    gap:2rem;
    padding: 0.2rem;
    background-color: #565960;
}

.program-header{
    display: flex;
    gap:5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

.stroke-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}
.cards{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cards>span{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap:1rem;
    
    justify-content: space-between;
}
@media screen and(max-width:768px) {
    .program-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .cards{
        flex-direction: column;
    }
} 