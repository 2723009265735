:root{
        --Lightgray:#D9D9D9;
        --gray:#9c9c9c;
        --orange:#f48915;
        --darGrey:#464D53;
        --caloryCard:#656565;
        --planCard: linear-gradient(210.41deg, #fa5042 1.14% ,#ffa739 100.75%);
        --appColor:#3c3f45;
}
.App{
        mix-blend-mode: overlay;
        background-color: var(--appColor);
        flex-direction: column;
        gap: 6rem;
        overflow: hidden;
}

*{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
.container{
        margin: 5rem;
}
    
.stroke-text{
        color: transparent;
        font-family: Arial, Helvetica, sans-serif;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;
}
.main
{
display: flex;
background-color: aqua;
}
.images{
        height: fit-content;
        width: "70%";
        background-color: aquamarine;
}
.quote{
        text-align:right;
}
.homemain{
        
        background-image: url("https://images.unsplash.com/photo-1590487988256-9ed24133863e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=428&q=80");
        
}
.modal-wrapper{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top:0;
        background-color: rgba(189,189 ,189, 0.9);
    }
.modal-container{
        position: fixed;
        max-width: 50rem;
        width: 25%;
        height: 55%;
        
        top:50%;
        left: 50%;
        padding: 2rem 3rem;
        border-radius: 0.5rem;
        transform: translate(-50%,-50%);
        background-color: #fff;
}

.blur{
        background-color: rgba(253,120,43,0.69);
        position: absolute;
        border-radius: 50%;
        filter:blur(10px);
        z-index: -9;
}